export function checkoutPageScripts() {
    if ($('body').hasClass('woocommerce-checkout')) {

        eventPicker($('.event_date'));

        !$('body').hasClass('woocommerce-order-received') && $('.order-details').theiaStickySidebar({
            additionalMarginTop: 120,
            additionalMarginBottom: 60,
            minWidth: 768,
        });

        window.Parsley
            .addValidator('notEqual', {
                requirementType: 'string',
                validateString: function (value, requirement) {
                    return value != requirement;
                },
                messages: {
                    en: 'Values is not equal.',
                }
            });

        let stripeForm = $('#order-payment');
        if (window.innerWidth >= 768) {
            $(stripeForm).appendTo('#order-payment-large');
        }
        stripeForm.removeClass('d-none');

        let onResize = lodash.debounce(function (event) {
            if (window.innerWidth >= 768) {
                let isInLarge = $('#order-payment-large').has('#order-payment').length;
                !isInLarge && $(stripeForm).appendTo('#order-payment-large');
            } else {
                let isInMob = $('#order-payment-mob').has('#order-payment').length;
                !isInMob && $(stripeForm).appendTo('#order-payment-mob');
            }
        }, 300);
        $(window).on('resize', onResize);

        $(document).on('click', '.coupon-form .coupon-form_apply', function () {
            $('#loader').show();
            let coupon = $('.coupon-form input').val();
            if (coupon) {
                $.ajax({
                    url: '',
                    method: 'POST',
                    data: {
                        coupon_code: coupon,
                        apply_coupon: 'Apply+coupon'
                    },
                    success: function (response) {
                        location.reload();
                    },
                    error: function (data) {
                        console.warn(data);
                    },
                });
            }
        });
        $('.woocommerce-remove-coupon').on('click', function (e) {
            let coupon = $(e.target).data('coupon');
            $('#loader').show();
            $.ajax({
                url: '',
                method: 'GET',
                data: {
                    remove_coupon: coupon
                },
                success: function (response) {
                    location.reload();
                },
                error: function (data) {
                    console.warn(data);
                },
            });
            return false;
        });
        // $(document).on('click', '.coupon-form .coupon-form_apply', function () {
        //     $('form#checkout_coupon input[name="coupon_code"]').val($('.coupon-form input').val());
        //     $('form#checkout_coupon button').trigger('click');
        // });

        var checkoutForm = $('form.checkout');


        checkoutForm.on('checkout_place_order', function () {
            if ($('#confirm-order-flag').length == 0) {
                checkoutForm.append('<input type="hidden" id="confirm-order-flag" name="confirm-order-flag" value="1">');
            }
            return true;
        });

        $('select[name="billing_state"]').on('change', function () {
            if ($(this).val() != 'billing_state') {
                $('#billing_state-errors').html('');
            }
        });

        // Custom Express shipping for Sale Products
        $('input[name=is_apply_express_shipping]').prop("disabled", false); // Unblock element
        $('input[name=is_apply_express_shipping]').on('change', function () {
            // $('#loader').show();
            $('label.shipping_method-item').toggleClass('checked');
            $.ajax({
                url: ajaxurl,
                method: 'GET',
                data: {
                    action: 'apply_express_shipping',
                    is_apply_express_shipping: this.value == 'express' ? 1 : 0
                },
                success: function (response) {
                    location.reload();
                },
                error: function (data) {
                    console.warn(data);
                },
            });
        });

        // Remember and restore checkout form data
        const fieldsToRestore = [
            'billing_first_name',
            'billing_last_name',
            'billing_email',
            'billing_phone',
            // 'billing_country',
            'billing_address_1',
            'billing_city',
            'billing_postcode',
            'billing_state',
            'billing_customer_height',
            'billing_customer_weight',
            'event_date',
            'order_comments',
            // 'payment_method',
        ];
        checkoutForm.on('change', function () {
            sessionStorage.setItem('checkoutForm', JSON.stringify(checkoutForm.serializeArray()));
        });

        $(document).ready(function () {
            let formStr = sessionStorage.getItem('checkoutForm');
            if (formStr && formStr.length) {
                let formData = JSON.parse(formStr);
                fieldsToRestore.forEach(function (item, key) {
                    let formItemToRestore = formData.find(o => o.name === item);
                    if (formItemToRestore.value) {
                        let formItem = $(checkoutForm).find('[name="' + formItemToRestore.name + '"]');
                        if (formItem.length && !formItem[0].type.startsWith('select')) {
                            if ($(formItem[0]).hasClass('flatpickr-input')) {
                                let timestamp = Date.parse(formItemToRestore.value);
                                const offset = (new Date()).getTimezoneOffset();
                                let itemDate = new Date(timestamp - (offset * 60 * 1000));
                                formItem[0].flatpickr().setDate(itemDate.toISOString().split('T')[0]);
                            } else {
                                $(formItem).val(formItemToRestore.value);
                            }
                        }
                        else {
                            $(checkoutForm).find('select[name="' + formItemToRestore.name + '"]').val(formItemToRestore.value);
                            $(checkoutForm).find('ul.select-options li[rel=' + formItemToRestore.value + ']').click();
                        }
                    }
                });

                sessionStorage.setItem('checkoutForm', formStr);
            }
        });

    }
}

function eventPicker($this) {
    $this.flatpickr({
        // altInput: true,
        // mode: "range",
        altFormat: 'd-M-Y',
        dateFormat: 'd-M-Y',
        minDate: "today",
        disableMobile: "true",
        onOpen: function (selectedDates, dateStr) {
            $('.flatpickr-calendar').append("<p>Please select your event date</p>");
            $('.flatpickr-calendar').append("<div class='calender-legend'>This may differ from your delivery date</div>");
        },
        // selectedDates: Date[], dateStr: string, instance: any
        onChange: function (selectedDates, dateStr, instance) {
            // Close the Flatpickr instance when a date is selected
            instance.close();
        }
    });

}